import '../styles/pages/p_info.sass';
import React from 'react';
import SEO from '../components/seo';

const Info = () => (
  <section className="important-info section-bg-dark full-screen">
    <SEO title="Важная информация" />

    <p>
      Обращаем ваше внимание, что информация представленная на сайте, носит только информационно-рекламный характер, не является офертой, публичной офертой в соответствии со статьей 641 Гражданского Кодекса Украины.
    </p>
    <p>
      Размещенные на сайте планировки квартир и других помещений не являются точными копиями проектной документации или документов технической инвентаризации и выполнены в рекламных целях для наглядного представления характеристик квартир и помещений.
    </p>
    <p>
      Элементы благоустройства, материалы и цвета отделки фасада, мест общего пользования, квартир и других помещений в строящемся комплексе могут отличаться от приведенных на сайте.
    </p>
    <p>
      Для получения актуальной информации об условиях продажи, а также характеристики квартир, нежилых помещений и мест общего пользования Вы можете обратиться в отдел продаж застройщика.
    </p>
  </section>
);

export default Info;
